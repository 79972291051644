import React from "react";
import artwizLogo from "../../images/caseimages/connectto.png";
import Intro from "../../images/caseimages/connectto_intro.png";

import TicketDetails from "../../images/caseimages/TicketDetails.png";
import OldTicketDetails from "../../images/caseimages/oldTicketDetails.JPG";
import Tickets from "../../images/caseimages/Tickets.png";
import OldTickets from "../../images/caseimages/oldTicket.JPG";
import Profile from "../../images/caseimages/Profile.png";
import OldProfile from "../../images/caseimages/oldProfile.JPG";
import Dashboard from "../../images/caseimages/Dashboard.png";
import OldDashboard from "../../images/caseimages/oldDashboard.jpg";

import ArrowIcon from "../../images/caseimages/arrow.png";
import FigmaIcon from "../../images/caseimages/logos_figma.png";
import VsIcon from "../../images/caseimages/vs-logo.png";
import MaterialIcon from "../../images/caseimages/material-logo.png";

function Connectto() {
  return (
    <div className="flex flex-col mt-8 px-4 sm:px-8 md:px-16 lg:px-20 xl:px-32">
      <ul className="grid gap-4 sm:grid-cols-1 md:grid-cols-2 mb-16">
        <li className="p-4 flex items-center justify-center flex-col ">
          <img src={artwizLogo} alt="Logo" style={{ maxWidth: "200px" }} />
          <h2 className="font-bold text-lg mb-2 mt-6">
            What ConnectTo is actually about
          </h2>
          <ul>
            <li className="mb-4">
              ConnectTo CRM is an all-in-one platform that simplifies customer
              support, helping businesses handle and resolve issues faster. When
              a customer calls, support agents can create a ticket, set its
              priority, and assign tasks to the right team. The system tracks
              each ticket in real time, showing if it's new, in progress, or
              done.
            </li>
            <li>
              The user-friendly dashboard lets technical support team view daily
              tasks, track ticket statuses, and manage workloads. Detailed
              ticket info, including files, comments, and history, ensures
              nothing is missed. Admins can manage users, monitor team
              performance, and generate reports. Real-time notifications and
              search tools improve team communication and efficiency.
            </li>
          </ul>
        </li>
        <li className=" p-4 ">
          <img src={Intro} alt="Intro" />
        </li>
      </ul>
      <ul className="grid gap-4 sm:grid-cols-1 md:grid-cols-2 mb-16">
        <li className="p-4">
          <h2 className="font-bold text-lg mb-2">My Role</h2>
          <p>
            My role in the ConnectTo CRM platform focused on designing the
            interface for the technical support team while contributing to the
            overall UI design. I developed user-friendly screens to enhance task
            management, ticket tracking, and issue resolution, ensuring that all
            ticket details, such as files, comments, and history, were easily
            accessible. Additionally, I modernized the visual appearance of the
            UI and worked on creating and maintaining a cohesive design system
            for the entire platform, ensuring consistency across all components
            and aligning with the evolving needs of the project.
          </p>
        </li>
        <li className="p-4">
          <ul className="flex flex-col md:flex-row">
            <li className="mb-4 md:w-1/2 md:pr-4">
              <h2 className="font-bold text-lg mb-2">Tools</h2>
              <div className="flex items-center mb-4">
                <img src={FigmaIcon} alt="Ai" className="w-8 mr-2" />
                <span>Figma </span>
              </div>
              <div className="flex items-center mb-4">
                <img src={VsIcon} alt="Ai" className="w-8 mr-2" />
                <span>Visual Studio(HTML/CSS)</span>
              </div>
              <div className="flex items-center">
                <img src={MaterialIcon} alt="Ai" className="w-8 mr-2" />
                <span>Material UI</span>
              </div>
            </li>
            <li className="md:w-1/2">
              <h2 className="font-bold text-lg mb-2">Skills</h2>
              <ul className="list-disc ml-6">
                <li>UX Researcher</li>
                <li>UX/UI designer</li>
                <li>UI Developer</li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>

      <div className="text-center pb-8 ">
        <h1 className=" uppercase text-2xl">User Research</h1>
      </div>
      <div className="mb-32 ">
        <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="border border-gray-light rounded-md p-4 ">
            <h3 class="pb-2 text-base font-bold">User Interviews:</h3>
            <p>
              Conducted 1-on-1 interviews with 10 users, including 3 Customer
              Support Agents, 4 Technical Support Agents, and 3 Administrators.
              Asked about their daily tasks, challenges with the current system,
              and desired improvements.
            </p>
          </div>
          <div className="border border-gray-light rounded-md p-4 ">
            <h3 class="text-base pb-2 font-bold">Surveys:</h3>
            <p>
              Distributed surveys to 24 users across various departments to
              gather quantitative data on their experiences with customer
              support tools. Focused on ease of use, task management, and ticket
              tracking efficiency.
            </p>
          </div>
        </div>

        <div class="grid grid-cols-1 md:grid-cols-2 gap-8 mt-8">
          <div className="border border-gray-light rounded-md p-4 ">
            <h3 class=" text-base pb-2 font-bold">Usability Testing:</h3>
            <p>
              Created prototypes and tested them with 8 users to observe how
              they navigate ticket creation, tracking, and user management.
              Collected feedback on the intuitiveness of the UI and how easily
              users could accomplish tasks.
            </p>
          </div>
          <div className="border border-gray-light rounded-md p-4 ">
            <h3 class="text-base pb-2 font-bold">Contextual Inquiry:</h3>
            <p>
              Observed 5 users in their natural work environment, analyzing how
              they manage customer issues and track tickets using existing
              systems. Noted time spent on common tasks like ticket logging,
              assigning tasks, and generating reports.
            </p>
          </div>
        </div>
      </div>

      <h1 className=" uppercase text-center text-2xl mb-8">Key Findings</h1>
      <ul className="grid gap-4 sm:grid-cols-1 md:grid-cols-2">
        <li className="mb-8">
          <h3 className="font-bold text-lg mb-2">Pain Points</h3>
          <p className="flex items-center">
            <span className="mr-4">
              <b>Customer Support Agents</b> - Difficulty tracking ticket status
              for due to complicated interfaces. Spent too much time on ticket
              creation and prioritization, leading to slower resolution times.
            </span>
            <img src={ArrowIcon} alt="arrow" className="ml-auto w-4 h-auto" />
          </p>
        </li>
        <li className="mb-8">
          <h3 className="font-bold text-lg mb-2">Needs</h3>
          <p>
            A streamlined ticket creation process, real-time ticket tracking,
            and easy access to all ticket-related information (comments,
            history, files).
          </p>
        </li>
      </ul>
      <ul className="grid gap-4 sm:grid-cols-1 md:grid-cols-2">
        <li className="mb-8">
          <h3 className="font-bold text-lg mb-2">Pain Points</h3>
          <p className="flex items-center">
            <span className="mr-4">
              <b>Technical Support Leads</b> - In the old design, switching
              between customer profiles and communication logs required multiple
              clicks, causing frustration and slowing down workflows.
            </span>
            <img src={ArrowIcon} alt="arrow" className="ml-auto w-4 h-auto" />
          </p>
        </li>
        <li className="mb-8">
          <h3 className="font-bold text-lg mb-2">Needs</h3>
          <p>
            A central dashboard to see all open tickets and their statuses,
            real-time notifications for new assignments, and improved
            communication tools.
          </p>
        </li>
      </ul>
      <ul className="grid gap-4 sm:grid-cols-1 md:grid-cols-2">
        <li className="mb-8">
          <h3 className="font-bold text-lg mb-2">Pain Points</h3>
          <p className="flex items-center">
            <span className="mr-4">
              <b>Administrators/Managers</b> - Struggled with generating reports
              and tracking team performance. Often needed to manually pull data
              from different systems.
            </span>
            <img src={ArrowIcon} alt="arrow" className="ml-auto w-4 h-auto" />
          </p>
        </li>
        <li className="mb-8">
          <h3 className="font-bold text-lg mb-2">Needs</h3>
          <p>
            Tools for real-time performance tracking, easy-to-generate reports
            on productivity, ticket resolution times, and user activity logs.
          </p>
        </li>
      </ul>
      {/* <div className="text-center pb-8 mt-16 ">
        <h1 className=" uppercase text-2xl">Insights</h1>
      </div>
      <div className="mb-32 ">
        <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="border border-gray-light rounded-md p-4 ">
            <h3 class="pb-2 text-base font-bold">
              Simplified Ticketing System:
            </h3>
            <p>
              Users need an intuitive interface to create, categorize, and
              prioritize tickets without unnecessary steps.
            </p>
          </div>
          <div className="border border-gray-light rounded-md p-4 ">
            <h3 class="text-base pb-2 font-bold">Real-Time Dashboards:</h3>
            <p>
              Users want clear overviews of daily tasks, open tickets, and their
              statuses to boost productivity and prevent missed tasks.
            </p>
          </div>
        </div>

        <div class="grid grid-cols-1 md:grid-cols-2 gap-8 mt-8">
          <div className="border border-gray-light rounded-md p-4 ">
            <h3 class=" text-base pb-2 font-bold">
              Efficient User Management:
            </h3>
            <p>
              Admins need more straightforward ways to track performance and
              generate reports, while support teams need real-time updates and
              notifications to stay on track.
            </p>
          </div>
          <div className="border border-gray-light rounded-md p-4 ">
            <h3 class="text-base pb-2 font-bold">
              Improved Search Functionality:
            </h3>
            <p>
              Users expressed the need for advanced search tools to quickly
              locate specific tickets, reports, or user data, helping them
              resolve issues more efficiently.
            </p>
          </div>
        </div>
      </div> */}
      <h1 className=" uppercase text-center text-2xl mt-8 pb-8">Solutions</h1>
      <h3 className="font-bold text-lg mb-2 text-center">Dashboard</h3>
      <ul className="grid gap-4 sm:grid-cols-1 md:grid-cols-2">
        <li className="mb-8">
          <div className="flex items-center">
            <div className="mb-4">
              <span class=" text-base pb-2 mr-2 font-bold">Old Version:</span>
              There was no dedicated dashboard screen. After logging in, users
              were directed to their profile page, which was unnecessary and
              disrupted workflow. The absence of an overview meant users lacked
              a clear starting point for daily tasks.
              <div className="mt-4">
                <img
                  src={OldDashboard}
                  alt="Intro"
                  className="max-w-full h-auto border border-gray-light mb-16"
                />
              </div>
            </div>
            <img src={ArrowIcon} alt="arrow" className="ml-auto w-4 h-auto" />
          </div>
        </li>
        <li className="mb-8">
          <div className="mb-4">
            <span class=" text-base pb-2 mr-2 font-bold">What Was Done:</span>A
            new dashboard was created to serve as the default landing page. It
            provides a centralized view of daily tasks, ticket statuses (new, in
            progress, and done), and pending actions. This streamlined interface
            allows users to prioritize their workload at a glance.
          </div>
          <img
            src={Dashboard}
            alt="Intro"
            className="max-w-full h-auto border border-gray-light mb-16"
          />
        </li>
      </ul>
      <h3 className="font-bold text-lg mb-2 text-center">Profile</h3>
      <ul className="grid gap-4 sm:grid-cols-1 md:grid-cols-2">
        <li className="mb-8">
          <div className="flex items-center">
            <div className="mb-4">
              <span class=" text-base pb-2 mr-2 font-bold">Old Version:</span>
              The profile page was the default landing page upon login and was
              cluttered with unnecessary information. It lacked quick access to
              frequently needed details.
              <div className="mt-4">
                <img
                  src={OldProfile}
                  alt="Intro"
                  className="max-w-full h-auto border border-gray-light mb-16"
                />
              </div>
            </div>
            <img src={ArrowIcon} alt="arrow" className="ml-auto w-4 h-auto" />
          </div>
        </li>
        <li className="mb-8">
          <div className="mb-4">
            <span class=" text-base pb-2 mr-2 font-bold">What Was Done:</span>A
            The profile page was redesigned with a cleaner layout to focus only
            on essential information.
          </div>
          <img
            src={Profile}
            alt="Intro"
            className="max-w-full h-auto border border-gray-light mb-16"
          />
        </li>
      </ul>
      <h3 className="font-bold text-lg mb-2 text-center">Tickets</h3>
      <ul className="grid gap-4 sm:grid-cols-1 md:grid-cols-2">
        <li className="mb-8">
          <div className="flex items-center">
            <div className="mb-4">
              <span class=" text-base pb-2 mr-2 font-bold">Old Version:</span>
              Tickets were displayed in a simple list without categorization or
              visual progress indicators. Users struggled to filter or
              prioritize tasks effectively.
              <div className="mt-4">
                <img
                  src={OldTickets}
                  alt="Intro"
                  className="max-w-full h-auto border border-gray-light mb-16"
                />
              </div>
            </div>
            <img src={ArrowIcon} alt="arrow" className="ml-auto w-4 h-auto" />
          </div>
        </li>
        <li className="mb-8">
          <div className="mb-4">
            <span class=" text-base pb-2 mr-2 font-bold">What Was Done:</span>A
            The tickets screen was revamped with a categorized view (New, In
            Progress, Done). Clear progress indicators and priority tags were
            introduced to help users quickly identify and focus on the most
            critical tickets.
          </div>
          <img
            src={Tickets}
            alt="Intro"
            className="max-w-full h-auto border border-gray-light mb-16"
          />
        </li>
      </ul>

      <h3 className="font-bold text-lg mb-2 text-center">Ticket Details</h3>
      <ul className="grid gap-4 sm:grid-cols-1 md:grid-cols-2">
        <li className="mb-8">
          <div className="flex items-center">
            <div className="mb-4">
              <span class=" text-base pb-2 mr-2 font-bold">Old Version:</span>
              All ticket details were displayed on a single, overcrowded screen.
              This cluttered layout made it hard for users to find specific
              information quickly.
              <div className="mt-4">
                <img
                  src={OldTicketDetails}
                  alt="Intro"
                  className="max-w-full h-auto border border-gray-light mb-16"
                />
              </div>
            </div>
            <img src={ArrowIcon} alt="arrow" className="ml-auto w-4 h-auto" />
          </div>
        </li>
        <li className="mb-8">
          <div className="mb-4">
            <span class=" text-base pb-2 mr-2 font-bold">What Was Done:</span>
            The ticket details screen was redesigned with tabs for Files,
            Comments, Descriptions, and History, decluttering the interface and
            making it easier for users to find specific information quickly.
          </div>
          <img
            src={TicketDetails}
            alt="Intro"
            className="max-w-full h-auto border border-gray-light mb-16"
          />
        </li>
      </ul>

      <h1 className=" uppercase text-center text-2xl pb-8">Conclusion</h1>
      <p className="bg-white pb-16 text-center">
        In conclusion, I worked closely with the UX/UI design team on the
        improvement process of the CRM platform. Together, we conducted user
        research, utilizing various methods like interviews and surveys. We
        applied our findings in the design process and implementation, testing
        the updates with users from different departments. My focus was
        specifically on the technical support interface, addressing their needs
        and pain points while considering the requirements of other users as
        well. This approach ensured the platform improvements were effective and
        user-centered.
      </p>
    </div>
  );
}

export default Connectto;
